import { graphql, Link } from "gatsby";
import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Navbar from "../components/Navbar";
import { Heading, Button, Box, Text } from "@chakra-ui/react";
import { HiArrowCircleLeft, HiArrowCircleRight } from "react-icons/hi";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Helmet from "react-helmet";
const blogPosts = (data) => {
  const { frontmatter, body } = data.data.mdx;
  const { next, previous } = data.pageContext;
  const image = getImage(frontmatter?.embeddedImagesLocal[0]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta name="description" content={frontmatter.title} />
        <title>Akaash Sinha | {frontmatter.title}</title>
      </Helmet>
      <Navbar />
      <article>
        <Box
          mx="auto"
          maxW="1280"
          borderWidth={1}
          borderRadius="lg"
          shadow="md"
          p={10}
        >
          <Box>
            <Heading textAlign={"center"}>{frontmatter.title}</Heading>
            <Box py={2}>
              <Text fontSize="xs" textAlign={"center"}>
                {frontmatter.date}
              </Text>
            </Box>
            <Box py={2}>
              {image && <GatsbyImage image={image} alt={frontmatter.title} />}
            </Box>
            <Box py={4}>
              <MDXRenderer>{body}</MDXRenderer>
            </Box>
            {/* Weird behavior where next is previous and previous is next */}
            {next === false ? null : (
              <>
                {next && (
                  <Link to={`/blog/${next.slug}`}>
                    <Button
                      mr={5}
                      mt={5}
                      leftIcon={<HiArrowCircleLeft />}
                      colorScheme="blue"
                      variant="solid"
                    >
                      {next.frontmatter.title}
                    </Button>
                  </Link>
                )}
              </>
            )}
            {previous === false ? null : (
              <>
                {previous && (
                  <Link to={`/blog/${previous.slug}`}>
                    <Button
                      mt={5}
                      rightIcon={<HiArrowCircleRight />}
                      variant="solid"
                      colorScheme="red"
                    >
                      {previous.frontmatter.title}
                    </Button>
                  </Link>
                )}
              </>
            )}
          </Box>
        </Box>
      </article>
    </>
  );
};

export default blogPosts;

export const query = graphql`
  query MyQuery($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
        date(formatString: "Do MMMM, YYYY")
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
